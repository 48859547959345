<template>
    <div class="page-content-menu">
      <div class="page-content-breadcrumbs">
        <Breadcrumbs v-if="breadcrumbs" :breadcrumbs="breadcrumbs" />
      </div>
      <b-row v-if="!hideTitle" class="page-content-title">
          <b-col cols="10">
              <slot name="title">
                  <h1 class="page-title" v-if="page && page.title">{{ page.title }}</h1>
              </slot>
          </b-col>
          <b-col cols="2" class="toolbar">
              <slot name="toolbar"></slot>
          </b-col>
      </b-row>
    </div>
</template>
<script>
import Breadcrumbs from './Breadcrumbs';
export default {
    props: ['breadcrumbs','page','hideTitle'],
    components: {
        Breadcrumbs
    },
    data(){
        return {

        };
    },
    mounted(){

    }
};
</script>
