<template>
    <nav aria-label="breadcrumb" v-if="breadcrumbs">
        <ol class="breadcrumb">
            <li v-for="(item,key) in breadcrumbs" v-bind:key="key"  class="breadcrumb-item">
                <b-link v-if="item.url" v-bind:to="item.url">{{ item.title }}</b-link>
                <span v-else>{{ item.title }}</span>
            </li>
        </ol>
    </nav>
</template>
    
<script>
export default {
    name: 'Breadcrumbs',
    props: ['breadcrumbs']
};
</script>