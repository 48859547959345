<template>
    <div class="page-sidebar-container">
        <ul class="page-sidebar" v-if="member">
            <li v-bind:class="setActiveSidebar('member.details')">
                <b-link :to="{name: 'users.manager.user', params: {userId: member.get('id')}}">Account Details</b-link>
            </li>
            <li v-bind:class="setActiveSidebar('member.changePassword')">
                <b-link :to="{name: 'users.manager.user.changePassword', params: {userId: member.get('id')}}">Change Password</b-link>
            </li>
            <li v-bind:class="setActiveSidebar('member.delete')">
                <b-link :to="{name: 'users.manager.user.delete', params: {userId: member.get('id')}}">Delete Member</b-link>
            </li>
        </ul>
    </div>
</template>

<script>
export default {
    props: ['active','member'],
    components: {

    },
    methods: {
        setActiveSidebar(name){
            return this.active === name ? 'active' : '';
        },
    },
};
</script>
