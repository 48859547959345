export default class Member{

    constructor(data){
        this.data = data;
    }

    get(name){
        if(name === undefined){
            return this.data;
        }

        return this.data[name] || null;
    }

    fullName(){
        let arr = [];

        if(this.get('firstName')){
            arr.push(this.get('firstName'));
        }

        if(this.get('lastName')){
            arr.push(this.get('lastName'));
        }

        if(arr.length > 0){
            return arr.join(' ');
        }

        return this.get('email');
    }

}
