<template>
    <div id="team">
      <PageMenu :breadcrumbs="breadcrumbs" :page="page"/>
      <div class="page-with-menu" v-if="member">
          <b-row>
            <b-col cols="4">
              <SidebarMember v-if="member" :active="activeSidebar" :member="member"/>
            </b-col>
            <b-col cols="8">
              <router-view :member="member" @set-member-sidebar="setMemberSidebar" @update-member="updateMember"></router-view>
            </b-col>
          </b-row>
      </div>
    </div>
</template>

<script>
import PageMenu from '@/common/components/PageMenu';
import SidebarMember from './../../../components/Sidebar.Member';
import Member from '@/modules/users/models/Member';
export default {
    components: {
      PageMenu,SidebarMember
    },
    data(){
        return {
          page: {
              title: "",
          },
          breadcrumbs: [
              {
                  title: "Dashboard",
                  url: this.$router.resolve({name: "app.dashboard"}).href
              },
              {
                  title: "My Team",
                  url: this.$router.resolve({name: "users.manager.browse"}).href
              },
              {
                  title: "User details",
              }
          ],
          member: null,
          activeSidebar: "member.details"
        };
    },

    methods: {
      setMemberSidebar(data){
        this.activeSidebar = data.active;
      },
      updateMember({member}){
        this.member = new Member(member);
        this.page.title = this.member.fullName();
      }
    },

    mounted(){
      this.$api.get(`user/${this.$route.params.userId}`).then(res => {
        this.member = new Member(res.data);
        this.page.title = this.member.fullName();
      }).catch(() => {console.log('cannot get user')});
    },
};
</script>
